import { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { mockDataTeam } from '../../data/mockData';
import Header from '../../components/Header';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useCompany from '../../hooks/useCompany';

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axios = useAxiosPrivate();
  const [users, setUsers] = useState([]);
  const company = useCompany();
  useEffect(() => {
    if (company !== undefined) {
      const fetchUsersbyLocation = async () => {
        try {
          const response = await axios.get(`/api/users/location/${company}`, {
            // params: {
            //   locationId: 1000,
            // },
          });
          const transformedData = response.data.map((user) => {
            const { username, locations, roles } = user;
            const locationNames = locations.map(
              (location) => location.locationName,
            );
            const roleNames = Object.keys(roles);
            return {
              username,
              locations: locationNames.join(', '),
              roles: roleNames.join(', '),
            };
          });
          transformedData.forEach((user, index) => {
            user.id = index + 1;
          });
          setUsers(transformedData);
          // Do something with the users data
        } catch (error) {
          // Handle error
        }
      };
      const fetchAllUsers = async () => {
        try {
          const response = await axios.get('/api/users');
          const transformedData = response.data.map((user) => {
            const { username, locations, roles } = user;
            const locationNames = locations.map(
              (location) => location.locationName,
            );
            const roleNames = Object.keys(roles);
            return {
              username,
              locations: locationNames.join(', '),
              roles: roleNames.join(', '),
            };
          });
          transformedData.forEach((user, index) => {
            user.id = index + 1;
          });
          setUsers(transformedData);
        } catch (error) {
          // Handle error
        }
      };
      if (company.length > 1) {
        fetchAllUsers();
      } else {
        fetchUsersbyLocation();
      }
    }
  }, [company, axios]);
  console.log(users);
  const columns = [
    {
      field: 'username',
      headerName: 'Name',
      flex: 1,
      cellClassName: 'name-column-cell',
    },
    // { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'roles', headerName: 'Role', flex: 1 },
    {
      field: 'locations',
      headerName: 'Store Access',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {params.value}
        </div>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Team" subtitle="Managing the Team Members" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        <DataGrid rows={users} columns={columns} />
      </Box>
    </Box>
  );
};

export default Team;
