const stores = {
  '0': [
    ["Demo Store", 0]
  ],
  '1': [
    ["branding", 1000],
    ["witsand", 1002],
    ["tulum", 6615],
    ["paco ciao", 2724],
    ["hippiefish", 5445],
    ["kayuca", 6522],
    ["ubuntu", 1004],

  ]
};

const companies = [
  ["Boogaards", '1'],
  ["Demo Company", '0']
]


export { stores, companies };
