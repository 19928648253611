const mockDayForecast = [
  { time: 8, revenue: 0 },
  { time: 9, revenue: 100 },
  { time: 11, revenue: 150 },
  { time: 13, revenue: 200 },
  { time: 15, revenue: 180 },
  { time: 17, revenue: 220 },
  { time: 19, revenue: 250 },
  { time: 21, revenue: 190 },
  { time: 23, revenue: 120 },
  { time: 24, revenue: 0 }
];

export default mockDayForecast;