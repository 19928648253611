import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Header from '../../components/Header';
import SimpleLineChart from '../../components/LineChart';
import ProgressBar from '../../components/ProgressBar';
/**
 * Renders a Bar component.
 *
 * @returns {JSX.Element} The rendered Bar component.
 */

const DayForecast = () => {
  const [selectedStoreId, setSelectedStoreId] = useState(1000); // Default store ID
  const handleStoreChange = (event) => {
    setSelectedStoreId(event.target.value);
  };

  return (
    <Box m="20px" display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Header title="Forecast" subtitle="Day forecasts" />
        <FormControl>
          <InputLabel id="store-select-label">Select Store</InputLabel>
          <Select
            labelId="store-select-label"
            id="store-select"
            value={selectedStoreId}
            onChange={handleStoreChange}
          >
            <MenuItem value={1000}>Branding</MenuItem>
            <MenuItem value={1002}>Witsand</MenuItem>
            <MenuItem value={6615}>Tulum</MenuItem>
            <MenuItem value={2724}>Paco Ciao</MenuItem>
            <MenuItem value={5445}>HippieFish</MenuItem>
            <MenuItem value={6522}>Kayuca</MenuItem>
            <MenuItem value={1004}>Ubuntu</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <SimpleLineChart />
      </Box>
      <Typography variant="h4" fontWeight="bold" mt={2}>
        Morning Forecast
      </Typography>
      <Box>
        <ProgressBar actual={1000} forecast={970} size="40" />
      </Box>
      <Typography variant="h4" fontWeight="bold" mt={2}>
        Midday Forecast
      </Typography>
      <Box>
        <ProgressBar actual={5480} forecast={5742} size="40" />
      </Box>
      <Typography variant="h4" fontWeight="bold" mt={2}>
        Evening Forecast
      </Typography>
      <Box>
        <ProgressBar actual={3500} forecast={7218} size="40" />
      </Box>
    </Box>
  );
};

export default DayForecast;
