import { Box, useTheme } from '@mui/material';
import React from 'react';
import { tokens } from '../theme';

const ProgressBar = ({ actual = 100, forecast = 230, size = '40', text }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const width = actual > forecast ? 100 : (actual / forecast) * 100;
  const remaining = actual > forecast ? 0 : forecast - actual;

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: `${size}px`,
        backgroundColor: colors.blueAccent[500],
        borderRadius: '4px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: `${width}%`,
          backgroundColor: colors.greenAccent[500],
          borderRadius: '4px',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {actual}/{forecast} ({remaining} left)
      </div>
    </Box>
  );
};

export default ProgressBar;
