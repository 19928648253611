import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import getWeekDay from '../helpers/getWeekDay';

const SimpleBarChart = ({ data }) => {
  // Extracting data from props
  const ochtendData = data.map((entry) => entry.ochtend);
  const middagData = data.map((entry) => entry.middag);
  const avondData = data.map((entry) => entry.avond);
  const xLabels = data.map((entry) =>
    (getWeekDay(entry.date) + '\n' + entry.date).slice(0, -3),
  );

  return (
    <BarChart // Set height based on the props
      series={[
        {
          data: ochtendData,
          label: 'Morning',
          id: 'morningId',
          color: '#E9D985',
        },
        { data: middagData, label: 'Midday', id: 'middayId', color: '#B2BD7E' },
        {
          data: avondData,
          label: 'Evening',
          id: 'eveningId',
          color: '#749C75',
        },
      ]}
      xAxis={[{ data: xLabels, scaleType: 'band' }]}
    />
  );
};

export default SimpleBarChart;
