import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
const Users = () => {
  const [users, setUsers] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // let isMounted = true;
    // const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get('api/users', {
          // signal: controller.signal,
        });
        const userNames = response.data.map((user) => user.username);
        setUsers(userNames);
      } catch (error) {
        console.error('Error fetching users', error);
        navigate('/signin', { state: { from: location.pathname } });
      }
    };
    getUsers();

    return () => {
      // isMounted = false;
      // controller.abort();
      // console.log('Cleanup called and request aborted');
    };
  }, [axiosPrivate, navigate, location.pathname]);

  return (
    <article>
      <h2>Users List</h2>
      {users?.length ? (
        <ul>
          {users.map((user, i) => (
            <li key={i}>{user}</li>
          ))}
        </ul>
      ) : (
        <p>No users found</p>
      )}
    </article>
  );
};

export default Users;
