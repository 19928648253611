import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { stores } from '../data/stores';
import useAuth from './useAuth';

const useCompany = () => {
  const [company, setCompany] = useState();
  const { auth } = useAuth();
  useEffect(() => {
    const fetchLocationsFromToken = () => {
      try {
        // Retrieve the access token from local storage or a more secure method
        const decoded = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
        const allowedLocations = decoded?.UserInfo?.locations || [];
        const isAdmin = decoded?.UserInfo?.roles?.includes(5150);
        if (isAdmin) {
          setCompany(Object.keys(stores));
        }
        else {
          setCompany(parseInt(Object.keys(stores).filter((company) => allowedLocations.includes(parseInt(company)))))
        }
      } catch (error) {
        console.error('Failed to decode token:', error);
        setCompany([]); // Reset or handle error by clearing the locations
      }
    };

    fetchLocationsFromToken();
  }, []); // Empty dependency array ensures this effect runs only once on component mount
  return company;
}


export default useCompany;