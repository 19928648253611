import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { stores } from '../data/stores';
import useAuth from './useAuth';

const useLocations = () => {
  const [locations, setLocations] = useState([]);
  const { auth } = useAuth();
  useEffect(() => {
    const fetchLocationsFromToken = () => {
      try {
        // Retrieve the access token from local storage or a more secure method
        const decoded = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
        const allowedLocations = decoded?.UserInfo?.locations || [];
        const isAdmin = decoded?.UserInfo?.roles?.includes(5150);
        const isOwner = decoded?.UserInfo?.roles?.includes(5155);
        const company = Object.keys(stores).filter((company) => allowedLocations.includes(parseInt(company)));
        if (isAdmin) {
          const allLocations = Object.values(stores).flat();
          setLocations(allLocations); // Set all locations if the user is an admin
        } else if (isOwner) {
          const ownerLocations = stores[company];
          setLocations(ownerLocations); // Set locations for the owner's company
        } else {
          // Filter out locations that are not in the allowed locations
          const namedLocations = stores[company].filter(store => allowedLocations.includes(store[1]));
          setLocations(namedLocations); // Directly set locations from the decoded token
        }
      } catch (error) {
        console.error('Failed to decode token:', error);
        setLocations([]); // Reset or handle error by clearing the locations
      }
    };

    fetchLocationsFromToken();
  }, []); // Empty dependency array ensures this effect runs only once on component mount
  return locations;
}


export default useLocations;