import React, { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine';
import mockDayForecast from '../data/mockDayForecast';

const SimpleLineChart = ({ data }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => {
      clearInterval(interval);
    };
  }, []);

  const xAxisData = mockDayForecast.map((item) => {
    const hour = item.time;
    const Time = new Date();
    Time.setHours(hour, 0, 0, 0);
    return Time;
  });
  const seriesData = mockDayForecast.map((item) => item.revenue);

  return (
    <LineChart
      xAxis={[{ data: xAxisData, scaleType: 'time', tickInterval: 'auto' }]}
      series={[
        {
          data: seriesData,
        },
      ]}
      height={400}
    >
      <ChartsReferenceLine
        x={currentTime}
        lineStyle={{ strokeDasharray: '10 5' }}
        labelStyle={{ fontSize: '10' }}
        label={`Current time: ${currentTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })}`}
        labelAlign="start"
      />
    </LineChart>
  );
};

export default SimpleLineChart;
