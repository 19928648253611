import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
const SelectionBox = ({
  selectedStoreId,
  handleStoreChange,
  allowedLocations,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="store-select-label"></InputLabel>
      <Select
        labelId="store-select-label"
        id="store-select"
        value={selectedStoreId}
        onChange={handleStoreChange}
      >
        {allowedLocations.map(([label, value]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectionBox;
