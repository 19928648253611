function getWeekDay(dateString) {
  const dateParts = dateString.split("-");
  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const year = parseInt(dateParts[2], 10) + 2000;

  const date = new Date(year, month, day);
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return weekdays[date.getDay()];
}

export default getWeekDay;