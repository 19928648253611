import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { tokens } from '../../theme';
import { red } from '@mui/material/colors';

function Copyright(props) {
  const theme = useTheme(tokens);
  const colors = tokens(theme.palette.mode);
  return (
    <Typography
      variant="body2"
      color={colors.primary[100]}
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://bytix.nl">
        www.bytix.nl
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const LOGIN_URL = '/auth';

export default function SignIn() {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from?.pathname || { pathname: '/' };
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const user = data.get('username');
    const pwd = data.get('password');

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        },
      );
      console.log(JSON.stringify(response?.data));
      const accessToken = response?.data?.accessToken;

      setAuth({ user, accessToken });
      setUser('');
      setPwd('');
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem('persist', persist);
  }, [persist]);
  const theme = useTheme(tokens);
  const colors = tokens(theme.palette.mode);
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper // Use Paper instead of Box for the outer component
          elevation={10} // Add some elevation for shadow effect
          sx={{
            marginTop: theme.spacing(8), // Adjust top margin
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '2%',
            padding: theme.spacing(3), // Add some padding inside the Paper
            background: colors.primary[400],
            color: colors.primary[100],
          }}
        >
          <Typography
            sx={{ mb: 1 }}
            component="h1"
            variant="h1"
            color={colors.primary[100]}
          >
            Bytix
          </Typography>
          <Avatar sx={{ m: 2, bgcolor: '#26a69a' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            sx={{ mb: 2 }}
            component="h3"
            variant="h3"
            color={colors.primary[100]}
          >
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              type="text"
              autoComplete="username"
              autoFocus
              ref={userRef}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              ref={userRef}
            />
            <Typography
              component="h6"
              variant="h6"
              color={red[500]}
              sx={{ mt: 1 }}
              ref={errRef}
            >
              {errMsg}
            </Typography>

            <Button
              type="submit"
              fullWidth // You can remove this if you don't want full width
              variant="contained"
              sx={{
                mt: 3, // Add some top margin
                mb: 2, // Add some bottom margin
              }}
            >
              Sign In
            </Button>
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={persist}
                  onChange={togglePersist}
                />
              }
              label="Remember me"
            />
          </Box>
        </Paper>
      </Container>
      <Copyright sx={{ mt: 8, mb: 1 }} />
    </ThemeProvider>
  );
}
