import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { Authprovider } from './context/AuthProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Authprovider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </Authprovider>
    </BrowserRouter>
  </React.StrictMode>
);

