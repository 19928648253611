import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import SignIn from "./scenes/signIn";
import Form from "./scenes/form";
import Calendar from "./scenes/calendar";
import TwoWeeksForecast from "./scenes/twoWeeksForecast";
import DayForecast from "./scenes/dayForecast";
import Admin from "./scenes/admin";
import RequireAuth from "./RequireAuth";
import PersistLogin from "./components/PersistLogin";
import { Routes, Route, useLocation } from "react-router-dom";
import { createContext, useState } from "react";
import Template from "./scenes/template";
import Staff from "./scenes/staff";

export const UserContext = createContext();

const ROLES = {
  'User': 5152,
  'Admin': 5150
}
function App() {
  const [theme, colorMode] = useMode();
  const [user, setUser] = useState({ loggedIn: false });
  const location = useLocation(); // Get the current location
  // Check if the current path is not /signin
  const showSidebarAndTopbar = location.pathname !== '/signin';

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {showSidebarAndTopbar && <Sidebar />}
            <main className="content">
              {showSidebarAndTopbar && <Topbar />}
              <Routes>
                {/* public routes */}
                <Route path="/signin" element={<SignIn />} />
                {/* protected routes */}
                <Route element={<PersistLogin />}>
                  <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                    <Route path="/" element={<TwoWeeksForecast />} />
                    {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                    <Route path="/team" element={<Team />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/twoweekforecast" element={<TwoWeeksForecast />} />
                    <Route path="/dayforecast" element={<DayForecast />} />
                    <Route path="/template" element={<Template />} />
                    <Route path="/staff" element={<Staff />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/team" element={<Team />} />
                  </Route>
                </Route>
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
