import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Button,
  useTheme,
  Modal,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Header from '../../components/Header';
import { tokens } from '../../theme'; // Ensure this import path is correct
import SelectionBox from '../../components/SelectionBox';
import useLocations from '../../hooks/useLocations';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function getSecondEntryOfFirstList(locations) {
  if (Array.isArray(locations) && locations.length > 0) {
    const firstList = locations[0];
    if (Array.isArray(firstList) && firstList.length > 1) {
      return firstList[1];
    }
  }
  return undefined; // Handle the case where the structure is not as expected
}

const Staff = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      cellClassName: 'name-column-cell',
      sortable: false,
      flex: 1,
    },
    {
      field: 'morning',
      headerName: 'Morning',
      type: 'number',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'afternoon',
      headerName: 'Afternoon',
      type: 'number',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'evening',
      headerName: 'Evening',
      type: 'number',
      editable: false,
      sortable: false,
      flex: 1,
    },
  ];

  const [open, setOpen] = useState(false); // State to manage modal visibility
  const [location, setLocation] = useState(null);
  const allowedLocations = useLocations();
  const [morning, setMorning] = useState(0);
  const [afternoon, setAfternoon] = useState(0);
  const [evening, setEvening] = useState(0);
  const dateOption = new Date();
  const year = dateOption.getFullYear();
  const month = String(dateOption.getMonth() + 1).padStart(2, '0');
  const day = String(dateOption.getDate()).padStart(2, '0');
  const dateValue = `${year}-${month}-${day}`;
  const [date, setDate] = useState(dateValue);
  const [loading, setLoading] = useState(true);
  const [staffData, setStaffData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    const initialStoreId = getSecondEntryOfFirstList(allowedLocations);
    if (initialStoreId !== undefined) {
      setLocation(initialStoreId);
    }
  }, [allowedLocations]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleStoreChange = (event) => {
    setLocation(event.target.value);
  };
  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get(`api/staff/${location}`);
      const formattedStaffData = response.data.map((staff) => {
        return {
          ...staff,
          date: new Date(staff.date).toLocaleDateString('en-GB', {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
          }),
        };
      });
      // Create staff for 7 days back with today if they are not in the data
      const currentDate = new Date();
      for (let i = 0; i < 8; i++) {
        const dateOption = new Date();
        dateOption.setDate(currentDate.getDate() - i);
        const year = dateOption.getFullYear();
        const month = String(dateOption.getMonth() + 1).padStart(2, '0');
        const day = String(dateOption.getDate()).padStart(2, '0');
        const dateValue = `${year}-${month}-${day}`;
        const date = new Date(dateValue).toLocaleDateString('en-GB', {
          weekday: 'short',
          day: 'numeric',
          month: 'short',
        });
        const existingStaff = formattedStaffData.find(
          (staff) => staff.date === date,
        );
        if (!existingStaff) {
          formattedStaffData.push({
            _id: `new-${i}`,
            date: date,
            morning: 0,
            afternoon: 0,
            evening: 0,
          });
        }
      }
      formattedStaffData.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });
      setStaffData(formattedStaffData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data', error);
      setLoading(false);
    }
  };
  const handleChange = (prop) => (event) => {
    if (prop === 'morning') {
      setMorning(event.target.value);
    } else if (prop === 'afternoon') {
      setAfternoon(event.target.value);
    } else if (prop === 'evening') {
      setEvening(event.target.value);
    } else if (prop === 'date') {
      setDate(event.target.value);
      console.log(event.target.value);
    }
  };

  useEffect(() => {
    if (location !== null) {
      setLoading(true); // Set loading state to true when fetching starts
    }

    if (location !== null) {
      fetchData();
    }
  }, [axiosPrivate, location]);

  const handleSubmit = async () => {
    try {
      const response = await axiosPrivate.get(`api/staff/${location}`);
      if (response.data) {
        // Data entry already exists, update it using PUT
        const existingEntry = response.data.find(
          (entry) => entry.date.split('T')[0] === date,
        );
        // console.log(existingEntry);
        if (existingEntry) {
          await axiosPrivate.put(`api/staff/${location}`, {
            date,
            morning,
            afternoon,
            evening,
          });
        } else {
          // Data entry doesn't exist, create it using POST
          await axiosPrivate.post(`api/staff/${location}`, {
            locationId: location,
            date,
            morning,
            afternoon,
            evening,
          });
        }
      }
      console.log('Data submitted successfully');
    } catch (error) {
      console.error('Error submitting data', error);
    }
    fetchData();
    handleClose(); // Close the modal after submitting
  };

  return (
    <Box m="20px" mt="10px">
      <Header
        title="Staffing Template"
        subtitle="Assign number of staff members for each day"
      />
      <Box display="flex" justifyContent="space-between">
        <Box height="65vh" width="50%" p="10px">
          <Typography mt="50px" variant="h3" color="text.primary">
            Please enter the number of staff members you believe were
            appropriate for each shift on the specified day. You need to provide
            the staffing numbers for the morning, afternoon, and evening shifts.
            This information will help us evaluate and adjust our staffing
            templates to better match our operational needs.
          </Typography>
        </Box>
        <Box
          height="65vh"
          width="50%"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300],
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              display: 'none',
            },
          }}
        >
          <Box mb="10px" display="flex" justifyContent="space-between">
            <Button
              onClick={handleOpen}
              sx={{
                bgcolor: colors.primary[400],
                color: colors.greenAccent[400],
                width: '50%',
                mr: '15px',
              }}
            >
              Edit Staffing Template
            </Button>
            <SelectionBox
              selectedStoreId={location}
              handleStoreChange={handleStoreChange}
              allowedLocations={allowedLocations}
            />
          </Box>
          <DataGrid
            rows={staffData}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            getRowId={(row) => row._id}
          />
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography sx={{ flex: 1 }}>Select Date:</Typography>
              <FormControl sx={{ flex: 2, bgcolor: colors.primary[500] }}>
                <Select
                  labelId="date-select-label"
                  id="date-select"
                  value={date}
                  onChange={handleChange('date')}
                  displayEmpty
                >
                  {Array.from({ length: 14 }, (_, idx) => {
                    const dateOption = new Date();
                    dateOption.setDate(dateOption.getDate() - idx);
                    // Format dateOption to YYYY-MM-DD
                    const year = dateOption.getFullYear();
                    const month = String(dateOption.getMonth() + 1).padStart(
                      2,
                      '0',
                    );
                    const day = String(dateOption.getDate()).padStart(2, '0');
                    const dateValue = `${year}-${month}-${day}`;
                    return (
                      <MenuItem key={idx} value={dateValue}>
                        {dateOption.toLocaleDateString('en-GB', {
                          weekday: 'short',
                          day: 'numeric',
                          month: 'short',
                        })}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography sx={{ flex: 1 }}>Morning staff:</Typography>
              <FormControl sx={{ flex: 2, bgcolor: colors.primary[500] }}>
                <Select
                  labelId="morning-select-label"
                  id="morning-select"
                  value={morning}
                  onChange={handleChange('morning')}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {Array.from({ length: 45 }, (_, idx) => (
                    <MenuItem key={idx} value={idx}>
                      {idx}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography sx={{ flex: 1 }}>Afternoon staff:</Typography>
              <FormControl sx={{ flex: 2, bgcolor: colors.primary[500] }}>
                <Select
                  labelId="afternoon-select-label"
                  id="afternoon-select"
                  value={afternoon}
                  onChange={handleChange('afternoon')}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {Array.from({ length: 45 }, (_, idx) => (
                    <MenuItem key={idx} value={idx}>
                      {idx}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography sx={{ flex: 1 }}>Evening staff:</Typography>
              <FormControl sx={{ flex: 2, bgcolor: colors.primary[500] }}>
                <Select
                  labelId="evening-select-label"
                  id="evening-select"
                  value={evening}
                  onChange={handleChange('evening')}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {Array.from({ length: 45 }, (_, idx) => (
                    <MenuItem key={idx} value={idx}>
                      {idx}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Button onClick={handleSubmit} sx={{ mt: 2 }}>
              Update Staff Template
            </Button>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Staff;
