import { useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  useTheme,
  Modal,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Header from '../../components/Header';
import { tokens } from '../../theme'; // Ensure this import path is correct
import SelectionBox from '../../components/SelectionBox';
import { stores } from '../../data/stores';
import useLocations from '../../hooks/useLocations';

const Template = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: 'revenue',
      headerName: 'Revenue',
      cellClassName: 'name-column-cell',
      sortable: false,
      flex: 1,
    },
    {
      field: 'staffCount',
      headerName: 'Number of Staff',
      type: 'number',
      editable: false,
      sortable: false,
      flex: 1,
    },
  ];

  const [location, setLocation] = useState({
    locationName: '',
    locationId: '',
    staffTemplate: Array.from({ length: 10 }, (_, index) => ({
      revenue: `${(index + 1) * 1000}-${(index + 2) * 1000}`,
      staffCount: 0,
      id: index,
    })),
  });

  const [open, setOpen] = useState(false); // State to manage modal visibility

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const allowedLocations = useLocations();

  const handleStoreChange = (event) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      locationId: event.target.value,
      locationName: event.target.value, // Assuming the value is the name, adjust if needed
    }));
  };

  const handleStaffNumberChange = (revenueRange, event) => {
    const newStaffTemplate = location.staffTemplate.map((item) =>
      item.revenue === revenueRange
        ? { ...item, staffCount: event.target.value }
        : item,
    );
    setLocation((prevLocation) => ({
      ...prevLocation,
      staffTemplate: newStaffTemplate,
    }));
  };

  const handleSubmit = () => {
    handleClose(); // Close the modal after submitting
  };

  return (
    <Box m="20px" mt="10px">
      <Header
        title="Staffing Form"
        subtitle="Assign number of staff members based on revenue"
      />
      <Box display="flex" justifyContent="space-between">
        <Box height="65vh" width="50%" p="10px">
          <Typography mt="50px" variant="h6" color="text.primary">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
            quaerat voluptates perferendis cum fuga, odio maiores nam dolorum
            modi, ratione exercitationem expedita accusamus ut nobis tempore
            earum libero beatae ipsa?
          </Typography>
        </Box>
        <Box
          height="65vh"
          width="50%"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300],
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              display: 'none',
            },
          }}
        >
          <Box mb="10px" display="flex" justifyContent="space-between">
            <Button
              onClick={handleOpen}
              sx={{
                bgcolor: colors.primary[400],
                color: colors.greenAccent[400],
                width: '50%',
                mr: '15px',
              }}
            >
              Edit Staffing Template
            </Button>
            <SelectionBox
              selectedStoreId={location.locationId}
              handleStoreChange={handleStoreChange}
              allowedLocations={allowedLocations}
            />
          </Box>
          <DataGrid
            rows={location.staffTemplate}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}
          >
            {location.staffTemplate.map((item, index) => (
              <Box
                key={index}
                m="10px"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography width={200} variant="h6" color="text.primary">
                  {item.revenue}:
                </Typography>
                <FormControl fullWidth sx={{ bgcolor: colors.primary[500] }}>
                  <Select
                    labelId={`${item.revenue}-label`}
                    id={`${item.revenue}-select`}
                    value={item.staffCount}
                    onChange={(event) =>
                      handleStaffNumberChange(item.revenue, event)
                    }
                  >
                    {Array.from({ length: 45 }, (_, idx) => (
                      <MenuItem key={idx} value={idx}>
                        {idx}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            ))}
            <Button onClick={handleSubmit}>Submit</Button>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Template;
