import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import getWeekDay from '../helpers/getWeekDay';

export default function DenseTable(props) {
  const rows = props.data || [];
  const theme = useTheme();

  return (
    <TableContainer
      component={Paper}
      style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}
    >
      <Table
        size="small"
        aria-label="a dense table"
        style={{ width: '100%', height: '100%' }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                fontSize: theme.typography.body1.fontSize,
                fontWeight: 'bold',
                border: '1px solid',
                borderColor: theme.palette.grey[300],
              }}
            >
              Date
            </TableCell>
            <TableCell
              align="right"
              style={{
                whiteSpace: 'nowrap',
                fontSize: theme.typography.body1.fontSize,
                border: '1px solid',
                borderColor: theme.palette.grey[300],
              }}
            >
              Morning
            </TableCell>
            <TableCell
              align="right"
              style={{
                whiteSpace: 'nowrap',
                fontSize: theme.typography.body1.fontSize,
                border: '1px solid',
                borderColor: theme.palette.grey[300],
              }}
            >
              Midday
            </TableCell>
            <TableCell
              align="right"
              style={{
                whiteSpace: 'nowrap',
                fontSize: theme.typography.body1.fontSize,
                border: '1px solid',
                borderColor: theme.palette.grey[300],
              }}
            >
              Evening
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.date}>
              <TableCell
                component="th"
                scope="row"
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: theme.typography.body1.fontSize,
                  border: '1px solid',
                  borderColor: theme.palette.grey[300],
                }}
              >
                {`${getWeekDay(row.date)} ${row.date.slice(0, -3)}`}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: theme.typography.body1.fontSize,
                  border: '1px solid',
                  borderColor: theme.palette.grey[300],
                }}
              >
                {row.ochtend}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: theme.typography.body1.fontSize,
                  border: '1px solid',
                  borderColor: theme.palette.grey[300],
                }}
              >
                {row.middag}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: theme.typography.body1.fontSize,
                  border: '1px solid',
                  borderColor: theme.palette.grey[300],
                }}
              >
                {row.avond}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
