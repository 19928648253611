import axios from 'axios';

const NODE_ENV = process.env.NODE_ENV;
const baseURL = NODE_ENV === 'production' ? 'https://api.klump.me/' : 'http://localhost:8080/';

export default axios.create({
  baseURL
});

export const axiosPrivate = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});