import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useState, useEffect } from 'react';
import Header from '../../components/Header';
import RevenueTable from '../../components/revenueTable';
import SimpleBarChart from '../../components/BarChart';
import SelectionBox from '../../components/SelectionBox';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useLocations from '../../hooks/useLocations';

/**
 * Safely retrieves the second entry of the first list within the provided locations array.
 *
 * @param {Array} locations - The list of locations.
 * @returns {any} The second entry of the first list, or undefined if not available.
 */
function getSecondEntryOfFirstList(locations) {
  if (Array.isArray(locations) && locations.length > 0) {
    const firstList = locations[0];
    if (Array.isArray(firstList) && firstList.length > 1) {
      return firstList[1];
    }
  }
  return undefined; // Handle the case where the structure is not as expected
}

/**
 * Renders a Bar component.
 *
 * @returns {JSX.Element} The rendered Bar component.
 */
const TwoWeeksForecast = () => {
  const allowedLocations = useLocations(); // Fetch allowed locations
  const [selectedStoreId, setSelectedStoreId] = useState(null); // Default store ID
  const [selectedData, setSelectedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const initialStoreId = getSecondEntryOfFirstList(allowedLocations);
    if (initialStoreId !== undefined) {
      setSelectedStoreId(initialStoreId);
    }
  }, [allowedLocations]);

  useEffect(() => {
    if (selectedStoreId !== null) {
      setLoading(true); // Set loading state to true when fetching starts
    }

    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get(
          `api/forecast/${selectedStoreId}`,
        );
        setSelectedData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
        setError(error);
        setLoading(false);
      }
    };
    if (selectedStoreId !== null) {
      fetchData();
    }
  }, [axiosPrivate, selectedStoreId]);

  const handleStoreChange = (event) => {
    setSelectedStoreId(event.target.value);
  };

  return (
    <Box m="20px" display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Header title="Forecast" subtitle="Two week ahead forecast" />
        <Box width="200px">
          {loading ? (
            <Box>Loading...</Box>
          ) : (
            <SelectionBox
              selectedStoreId={selectedStoreId}
              handleStoreChange={handleStoreChange}
              allowedLocations={allowedLocations}
            />
          )}
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box height="75vh">
            {loading ? (
              <Box>Loading...</Box>
            ) : selectedData ? (
              <SimpleBarChart data={selectedData} />
            ) : error ? (
              <Box>Error fetching data</Box>
            ) : (
              <Box>No data available</Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box height="75vh">
            <RevenueTable data={selectedData} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TwoWeeksForecast;
